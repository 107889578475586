import axios from 'axios';
import styles from './login.module.css';
import { Button, Input } from 'antd';
import { useState } from 'react';
import { apiUrl } from '../../lib/constants';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const router = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [failed, setFailed] = useState(false);

  const login = async() => {
    try {
      if(username && username !== "" && password && password !== "") {
        const data = {
          username,
          password
        }
        const headers = {'Content-Type': 'application/json'};
        const response = await axios.post(`${apiUrl}/login`, data, headers);
        if(response) {
          const token = response.data?.token;
          localStorage.setItem("token", `Bearer ${token}`);
          router("/");
        }
      }
    }
    catch(error) {
      console.log(error);
      setFailed(true);
    }
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.loginContainer} style={{border: `1px solid ${failed ? "red" : "transparent"}`}}>
        <Input placeholder='Username' value={username || ""} onChange={(e) => setUsername(e.target.value)}/> 
        <Input placeholder='Password' type={"password"} value={password || ""} onChange={(e) => setPassword(e.target.value)}/> 
        <Button type="primary" style={{color: "#fff"}} onClick={login}>
          Login
        </Button>
      </div>
    </div>
  );
}

export default Login;

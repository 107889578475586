import axios from "axios";
import { toast } from "react-toastify";
import { apiUrl } from "./constants";

const axiosInstance = axios.create({
    baseURL: apiUrl,
});

axiosInstance.interceptors.request.use(request => {
    const accessToken = localStorage.getItem("token") ? localStorage.getItem("token") : null;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    }
    request.headers = headers;
    return request;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if(error.response) {
      toast.error(error.response?.data?.message, {
        position: "top-center"
      });
      console.log("ERROR RESPONSE", error.response);
      if(error.response?.status === 401 || error.response?.status === 403) {
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

import styles from './grid.module.css';
import Level from '../Level';
import { DeleteOutlined, EyeFilled } from '@ant-design/icons';
import { Button, Modal, Tabs } from 'antd';
import apiClient from '../../lib/apiClient';
import { useState } from 'react';

const Grid = ({data}) => {

  const [showDetails, setShowDetails] = useState(false);

  const removeTrader = async() => {
    try {
      const response = await apiClient.delete(
        `/${data.id}`,
      );
      console.log(response);
    }
    catch(error) {
      console.log(error);
      window.location.reload();
    }
  }

  const items = [
    {
      key: '1',
      label: 'Chart',
      children: <>
        <div className={styles.levels}>
          {data.levels?.map(obj => <Level key={Math.random() * 100} data={obj} children={data.transactions?.filter(tx => tx.price === obj.price)} />)}
        </div>
      </>
    },
    {
      key: '2',
      label: 'Data',
      children: <>
        <div className='column' style={{width: "100%"}}>
          <table>
            <tr>
              <th>Property</th>
              <th>Value</th>
            </tr>
            <tr>
              <td>Symbol</td>
              <td>{data.symbol}</td>
            </tr>
            <tr>
              <td>Id</td>
              <td>{data.id}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{data.status}</td>
            </tr>
            <tr>
              <td>Money In</td>
              <td>{Number(data.moneyIn).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Profit taken</td>
              <td>{Number(data.profitTaken).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Speed</td>
              <td>{Number(data.speed).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Leverage</td>
              <td>{Number(data.leverage).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Min Price</td>
              <td>{Number(data.minPrice).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Max Price</td>
              <td>{Number(data.maxPrice).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Coverage</td>
              <td>{Number(data.coverage).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Required Transactions</td>
              <td>{Number(data.requiredTransactions).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Required Balance</td>
              <td>{Number(data.requiredBalance).toFixed(2)}</td>
            </tr>
            {data.type === "MORTAL" && <tr>
              <td>Aim</td>
              <td>{Number(data.aim).toFixed(2)}</td>
            </tr>}
            {data.type !== "IMMORTAL" && <>
              <tr>
                <td>Lives</td>
                <td>{Number(data.lives)}</td>
              </tr>
              <tr>
                <td>Max Balance Used</td>
                <td>{Number(data.maxMoneyIn).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Transactions</td>
                <td>{Number(data.transactions.filter(obj => obj.status === "FILLED").length).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Accumulated Profit</td>
                <td>{Number(data.accumulatedProfit).toFixed(2)}</td>
              </tr>
            </>
            }
            {data.type === "Range" && <>
              <tr>
                <td>Max Price</td>
                <td>{Number(data.maxPrice).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Min Price</td>
                <td>{Number(data.minPrice).toFixed(2)}</td>
              </tr>
            </>}
            <tr>
              <td>TakeProfit</td>
              <td>{Number(data.takeProfit).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Stop Loss</td>
              <td>{Number(data.stopLoss).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Peak</td>
              <td>{Number(data.peak).toFixed(2)}</td>
            </tr>
            <tr>
              <td>Created At</td>
              <td>{new Date(data.createdAt).toDateString()}</td>
            </tr>
          </table>
        </div>
      </>
    },
  ];

  return (
    <div className={styles.gridContainer}>
      <div className={styles.grid}>
        <div className={styles.header}>
          <div className={styles.mainHeader}>
            <div className={styles.flexBetween}>
              <div className={styles.symbolData}>
                <h1 className={styles.headerText}>{data.symbol}</h1>
                <span> / {Number(data.currentPrice).toFixed(2)}</span>
              </div>
              <span className={`${styles.headerText} ${styles.headerProfit}`} style={{backgroundColor: `${Number(data.profit) > 0 ? "#0ecb81" : "#f6465d"}`}}>{Number(data.profit).toFixed(2) || 0}$</span>
            </div>
            <div className={styles.flexStart}>
              <div className={styles.tag}>
                {data?.type}
              </div>
              <div className={styles.tag}>
                {data?.mode}
              </div>
            </div>
            <div className={styles.flexBetween}>            
              <Button icon={<EyeFilled />} type="primary" style={{ color: "#fff", width: "49%" }} onClick={() => setShowDetails(true)}>
                Show Details
              </Button>
              <Button icon={<DeleteOutlined />} style={{ width: "49%" }} onClick={removeTrader}>
                DELETE
              </Button>
            </div>
          </div>
        </div>
        <>
        <Modal
          title={data.symbol}
          open={showDetails}
          okText={"Ok"}
          onOk={() => setShowDetails(false)}
          onCancel={() => setShowDetails(false)}
          okButtonProps = {{
            style: {color: "#fff"}
          }}
        >
          <Tabs defaultActiveKey="1" items={items} />
        </Modal>
        </>
      </div>
    </div>
  );
}

export default Grid;
